import {
  ProviderIn,
  GetAdminApplicationListIn,
  GetAdminApplicationListOut,
  GetAdminApplicationFullOut,
} from '@repo/common/dtos';
import axios from 'axios';

class Tenant {
  baseUrl: string;
  constructor(parentUrl: string) {
    this.baseUrl = `${parentUrl}/tenant`;
  }

  async createProvider(data: ProviderIn): Promise<string> {
    const response = await axios.post<string>(`${this.baseUrl}/provider`, data);
    return response.data;
  }

  async getApplications(type: string, data: GetAdminApplicationListIn): Promise<GetAdminApplicationListOut> {
    const response = await axios.post<GetAdminApplicationListOut>(`${this.baseUrl}/application/type/${type}`, data);
    return response.data;
  }

  async getApplication(applicationId: string): Promise<GetAdminApplicationFullOut> {
    const response = await axios.get<GetAdminApplicationFullOut>(`${this.baseUrl}/application/${applicationId}`);
    return response.data;
  }
}

export default Tenant;
