import { GetReceivablesDocumentOut } from '@repo/common/dtos';
import axios from 'axios';
import Company from './company';
import Application from './application';

class Representative {
  baseUrl: string;
  application: Application;
  company: Company;

  constructor(parentUrl: string) {
    this.baseUrl = `${parentUrl}/representative`;
    this.application = new Application(this.baseUrl);
    this.company = new Company(this.baseUrl);
  }

  async uploadEmployeeFile(cnpj: string, employeeId: string, documentPathUrl: string, documentName: string, documentType: string): Promise<string> {
    const response = await axios.put<string>(`${this.baseUrl}/company/${cnpj}/employee/document/upload`, {
      employeeId,
      documentPathUrl,
      documentType,
      documentName,
    });
    return response.data;
  }

  async getDocument(gcpPath: string): Promise<GetReceivablesDocumentOut> {
    const response = await axios.post<GetReceivablesDocumentOut>(`${this.baseUrl}/document`, { gcpPath });
    return response.data;
  }

  async postWhatsAppExitSurvey(idToken: string, applicationId: string): Promise<Response> {
    return fetch(`${this.baseUrl}/whatsapp/exit-survey?application_id=${applicationId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'id-token': idToken,
      },
      keepalive: true,
    }).then((res) => {
      if (res.ok) {
        return res;
      }
      throw new Error(res.statusText);
    });
  }

  postWhatsAppExitSurveyTest(whatsappNumber: string, applicationId: string): boolean {
    return navigator.sendBeacon(`${this.baseUrl}/whatsapp/exit-survey?whatsapp_number=${whatsappNumber}&application_id=${applicationId}`);
  }
}

export default Representative;
