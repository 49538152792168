import axios from 'axios';
import { FunnelInput, FunnelOperationDeleteIn, FunnelOperationIn, ModifyFunnelOperationStatusIn, OperationsChecklistOutput, SaveFunnelIn, ModifyFunnelStepIn } from '@repo/common/dtos';

class FunnelService {
  baseUrl: string;

  constructor(parentUrl: string) {
    this.baseUrl = `${parentUrl}/funnel`;
  }

  async getFunnelStages(): Promise<FunnelInput> {
    const response = await axios.get<FunnelInput>(`${this.baseUrl}`);
    return response.data;
  }

  async saveFunnel(data: SaveFunnelIn): Promise<undefined> {
    await axios.post<undefined>(`${this.baseUrl}/version/save`, data);
  }

  async createFunnelOperation(data: FunnelOperationIn): Promise<OperationsChecklistOutput> {
    const response = await axios.post<OperationsChecklistOutput>(`${this.baseUrl}/operation`, data);
    return response.data;
  }

  generateFunnelForApplication(applicationId: string) {
    return axios.post<string>(`${this.baseUrl}/application/${applicationId}`);
  }

  patchApplicationOperation(applicationId: string, data: ModifyFunnelOperationStatusIn) {
    return axios.patch<undefined>(`${this.baseUrl}/application/${applicationId}/operation`, data);
  }

  lockFunnelStep(applicationId: string, data: ModifyFunnelStepIn) {
    return axios.patch<string>(`${this.baseUrl}/application/${applicationId}/step`, data);
  }

  deleteFunnelOperation(data: FunnelOperationDeleteIn) {
    return axios.delete<undefined>(`${this.baseUrl}/operation`, {
      data,
    });
  }
}

export default FunnelService;
