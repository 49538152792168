import {
  GetReceivablesDocumentOut,
  BorderoOutput,
  ParcelInput,
  ReceivablesCompanyChangeIn,
  BorderoOCROut,
  ApplicationNoteIn,
  ReceivablesApplicationSummary,
  WhatsappMessageIn,
  ChangeApplicationNoteIn,
  ChangeAdminNotificationIn,
  GetAdminNotificationListIn,
  GetAdminNotificationListOut,
  FraudScore,
  WhatsappNotRepliedOut,
  WhatsappBusinessTemplate,
  WhatsappChatFilterOut,
  TenantLeadIn,
  WhatsappConversationStatusListOut,
  MetricsChartDescription,
  MetricsCohortSummary,
  TenantIn,
  Tenant,
  GetAdminRepaymentsCohortsReceivablesAdminMetricsRepaymentsCohortsGetParams,
  GetAdminRepaymentsCohortsSummaryReceivablesAdminMetricsRepaymentsCohortsSummaryGetParams,
  CnpjContractDetailsList,
  ChangeWhatsappConversationIn,
  ApplicationOut,
  GetPublicEntityIn,
  NewAdminApplicationIn,
  ApplicationHistory,
  StatusFunnelCountOut,
  FunnelLeadTimeOut,
  CollectionStatusPerformance,
  GetPublicEntity,
} from '@repo/common/dtos';
import axios, { AxiosResponse } from 'axios';
import { ChangeTenantLeadIn } from '../dtos/changeTenantLeadIn';
import { GetSalesFunnelReceivablesAdminMetricsSalesFunnelGetParams } from '../dtos/getSalesFunnelReceivablesAdminMetricsSalesFunnelGetParams';
import Funnel from './admin/funnel';
import Operation from './operation';
import { ExportPublicEntitiesReceivablesAdminPublicEntityExportPostParams } from '../dtos/exportPublicEntitiesReceivablesAdminPublicEntityExportPostParams';

class Admin {
  baseUrl: string;
  funnel: Funnel;
  operation: Operation;

  constructor(parentUrl: string) {
    this.baseUrl = `${parentUrl}/admin`;
    this.funnel = new Funnel(this.baseUrl);
    this.operation = new Operation(this.baseUrl);
  }

  async getDocument(gcpPath: string, responseDisposition: string | null = null): Promise<GetReceivablesDocumentOut> {
    const response = await axios.get<GetReceivablesDocumentOut>(`${this.baseUrl}/document`, {
      params: { gcp_path: gcpPath, response_disposition: responseDisposition },
    });
    return response.data;
  }

  async getBordero(applicationId: string): Promise<BorderoOCROut> {
    const response = await axios.get<BorderoOCROut>(`${this.baseUrl}/application/${applicationId}/bordero`);
    return response.data;
  }

  async updateBorderoCalculation(applicationId: string, bordero: ParcelInput): Promise<BorderoOutput> {
    const response = await axios.patch<BorderoOutput>(
      `${this.baseUrl}/application/${applicationId}/bordero/calculate`,
      { parcels: [bordero], userApproved: false },
    );
    return response.data;
  }

  async updateBordero(applicationId: string, bordero: ParcelInput): Promise<BorderoOutput> {
    const response = await axios.patch<BorderoOutput>(
      `${this.baseUrl}/application/${applicationId}/bordero`,
      { parcels: [bordero], userApproved: false },
    );
    return response.data;
  }

  async updateCompany(companyId: string, company: ReceivablesCompanyChangeIn): Promise<string> {
    const response = await axios.patch<string>(
      `${this.baseUrl}/company/${companyId}`,
      company,
    );
    return response.data;
  }

  async createApplication(intakeData: NewAdminApplicationIn): Promise<ApplicationOut> {
    const response = await axios.post<ApplicationOut>(`${this.baseUrl}/application`, intakeData);
    return response.data;
  }

  async addNote(applicationId: string, note: ApplicationNoteIn): Promise<string> {
    const response = await axios.post<string>(
      `${this.baseUrl}/application/${applicationId}/note`,
      note,
    );
    return response.data;
  }

  async updateNote(applicationId: string, note: ChangeApplicationNoteIn): Promise<string> {
    const response = await axios.patch<string>(
      `${this.baseUrl}/application/${applicationId}/note`,
      note,
    );
    return response.data;
  }

  async deleteNote(applicationId: string, noteId: string): Promise<string> {
    const response = await axios.delete<string>(
      `${this.baseUrl}/application/${applicationId}/note/${noteId}`,
    );
    return response.data;
  }

  async getOcrSummary(applicationId: string): Promise<ReceivablesApplicationSummary> {
    const response = await axios.get<ReceivablesApplicationSummary>(`${this.baseUrl}/application/${applicationId}/summary`);
    return response.data;
  }

  async getFraudScore(applicationId: string): Promise<FraudScore> {
    const response = await axios.get<FraudScore>(`${this.baseUrl}/application/${applicationId}/fraud-score`);
    return response.data;
  }

  async sendWhatsappMessage(data: WhatsappMessageIn): Promise<undefined> {
    await axios.post<void>(`${this.baseUrl}/whatsapp/send`, data);
  }

  async addNotification(data: ChangeAdminNotificationIn): Promise<AxiosResponse> {
    return axios.post<void>(`${this.baseUrl}/notification`, data);
  }

  async getWhatsappTemplates(): Promise<WhatsappBusinessTemplate[]> {
    const response = await axios.get<WhatsappBusinessTemplate[]>(`${this.baseUrl}/whatsapp/template`);
    return response.data;
  }

  async getWhatsappCustomTemplates(): Promise<WhatsappBusinessTemplate[]> {
    const response = await axios.get<WhatsappBusinessTemplate[]>(`${this.baseUrl}/whatsapp/custom_template`);
    return response.data;
  }

  async createWhatsappCustomTemplate(data: WhatsappBusinessTemplate): Promise<string[]> {
    const response = await axios.post<string[]>(`${this.baseUrl}/whatsapp/custom_template`, data);
    return response.data;
  }

  async deleteWhatsappCustomTemplate(name: string): Promise<string[]> {
    const response = await axios.delete<string[]>(`${this.baseUrl}/whatsapp/custom_template?name=${name}`);
    return response.data;
  }

  async getNotifications(data: GetAdminNotificationListIn): Promise<GetAdminNotificationListOut> {
    const response = await axios.post<GetAdminNotificationListOut>(`${this.baseUrl}/notification/search`, data);
    return response.data;
  }

  async updateNotification(notificationId: string, data: ChangeAdminNotificationIn): Promise<AxiosResponse> {
    return axios.patch<void>(`${this.baseUrl}/notification/${notificationId}`, data);
  }

  async deleteNotification(notificationId: string): Promise<AxiosResponse> {
    return axios.delete<void>(`${this.baseUrl}/notification/${notificationId}`);
  }

  async getWhatsAppUnreplied(): Promise<WhatsappNotRepliedOut> {
    const response = await axios.get<WhatsappNotRepliedOut>(`${this.baseUrl}/whatsapp/unreplied`);
    return response.data;
  }

  async getWhatsappFilteredChats(desiredFilter: string): Promise<WhatsappChatFilterOut[]> {
    const response = await axios.post<WhatsappChatFilterOut[]>(`${this.baseUrl}/whatsapp/filter`, {
      messageFilter: desiredFilter,
    });
    return response.data;
  }

  async patchMarkAsReplied(data: ChangeWhatsappConversationIn): Promise<AxiosResponse> {
    return axios.patch<void>(`${this.baseUrl}/whatsapp/conversation`, data);
  }

  async getCohortRepaymentChart(params: GetAdminRepaymentsCohortsReceivablesAdminMetricsRepaymentsCohortsGetParams): Promise<MetricsChartDescription> {
    const searchParams = new URLSearchParams();
    if (params.start_date) {
      searchParams.set('start_date', params.start_date);
    }
    if (params.end_date) {
      searchParams.set('end_date', params.end_date);
    }
    const response = await axios.get<MetricsChartDescription>(`${this.baseUrl}/metrics/repayments/cohorts?${searchParams.toString()}`);
    return response.data;
  }

  async getCohortSummary(params: GetAdminRepaymentsCohortsSummaryReceivablesAdminMetricsRepaymentsCohortsSummaryGetParams): Promise<MetricsCohortSummary[]> {
    const searchParams = new URLSearchParams();
    if (params.start_date) {
      searchParams.set('start_date', params.start_date);
    }
    if (params.end_date) {
      searchParams.set('end_date', params.end_date);
    }
    if (params.recalculate_interest) {
      searchParams.set('recalculate_interest', String(params.recalculate_interest));
    }
    const response = await axios.get<MetricsCohortSummary[]>(`${this.baseUrl}/metrics/repayments/cohorts/summary?${searchParams.toString()}`);
    return response.data;
  }

  async getApplicationSalesConversion(data: unknown) {
    const response = await axios.post<ApplicationHistory[][]>(`${this.baseUrl}/metrics/application_sales_conversion`, data);
    return response.data;
  }

  async getSalesFunnel(params: GetSalesFunnelReceivablesAdminMetricsSalesFunnelGetParams): Promise<MetricsChartDescription> {
    const searchParams = new URLSearchParams();
    if (params.start_date) {
      searchParams.set('start_date', params.start_date);
    }
    if (params.end_date) {
      searchParams.set('end_date', params.end_date);
    }
    const response = await axios.get<MetricsChartDescription>(`${this.baseUrl}/metrics/sales/funnel?${searchParams.toString()}`);
    return response.data;
  }

  async getConversationStatus(): Promise<WhatsappConversationStatusListOut> {
    const response = await axios.get<WhatsappConversationStatusListOut>(`${this.baseUrl}/whatsapp/conversation-status`);
    return response.data;
  }

  async deleteConversationStatus(status: string): Promise<AxiosResponse> {
    return axios.delete<void>(`${this.baseUrl}/whatsapp/conversation-status?conversation_status=${status}`);
  }

  async createConversationStatus(status: string): Promise<AxiosResponse> {
    return axios.post<void>(`${this.baseUrl}/whatsapp/conversation-status?conversation_status=${status}`);
  }

  async getTenantLeads(): Promise<TenantLeadIn[]> {
    const response = await axios.get<TenantLeadIn[]>(`${this.baseUrl}/tenant/lead`);
    return response.data;
  }

  async createTenant(data: TenantIn): Promise<string> {
    const response = await axios.post<string>(`${this.baseUrl}/tenant`, data);
    return response.data;
  }

  async getTenants(): Promise<Tenant[]> {
    const response = await axios.get<Tenant[]>(`${this.baseUrl}/tenant`);
    return response.data;
  }

  async patchTenant(data: ChangeTenantLeadIn): Promise<string> {
    const response = await axios.patch<string>(`${this.baseUrl}/tenant/lead`, data);
    return response.data;
  }

  async getTransparencyCNPJs(municipalities: string[]): Promise<string[]> {
    const response = await axios.post<string[]>(`${this.baseUrl}/tp-contracts`, { municipalities });
    return response.data;
  }

  async searchTransparencyWithCNPJ(cnpj: string): Promise<CnpjContractDetailsList> {
    const response = await axios.get<CnpjContractDetailsList>(`${this.baseUrl}/tp-contracts/cnpj/${cnpj}`);
    return response.data;
  }

  async getMunicipalities(): Promise<string[]> {
    const response = await axios.get<string[]>(`${this.baseUrl}/tp-contracts/municipalities`);
    return response.data;
  }

  async getBorderoCSV(): Promise<Record<string, string | number>> {
    const response = await axios.get<Record<string, string | number>>(`${this.baseUrl}/bordero/csv`);
    return response.data;
  }

  async getOtmowPhoneNumbers(): Promise<{ phoneNumbers: string[] }> {
    const response = await axios.get<{ phoneNumbers: string[] }>(`${this.baseUrl}/whatsapp/phone_numbers`);
    return response.data;
  }

  async getFunnelStatusCount(startDate: string | null, endDate: string | null): Promise<StatusFunnelCountOut[]> {
    const response = await axios.post<StatusFunnelCountOut[]>(`${this.baseUrl}/metrics/funnel`, null, {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    });
    return response.data;
  }

  async getFunnelExport(startDate: string | null, endDate: string | null): Promise<AxiosResponse<Blob>> {
    const response = await axios.post(`${this.baseUrl}/metrics/funnel/export`, null, {
      responseType: 'blob',
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    });
    return response;
  }

  async getFunnelLeadTime(startDate: string | null, endDate: string | null, useWorkingDays: boolean): Promise<FunnelLeadTimeOut> {
    const response = await axios.post<FunnelLeadTimeOut>(`${this.baseUrl}/metrics/funnel/lead-time`, null, {
      params: {
        start_date: startDate,
        end_date: endDate,
        use_working_days: useWorkingDays,
      },
    });
    return response.data;
  }

  async getFunnelLeadTimeExport(startDate: string | null, endDate: string | null): Promise<AxiosResponse<Blob>> {
    const response = await axios.post(`${this.baseUrl}/metrics/funnel/lead-time/export`, null, {
      responseType: 'blob',
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    });
    return response;
  }

  async getLoanPerformance(startDate: string | null, endDate: string | null, includeInterest: boolean): Promise<CollectionStatusPerformance[]> {
    const response = await axios.post<CollectionStatusPerformance[]>(`${this.baseUrl}/metrics/loan-performance`, null, {
      params: {
        start_date: startDate,
        end_date: endDate,
        include_interest: includeInterest,
      },
    });
    return response.data;
  }

  async getPublicEntities(input?: GetPublicEntityIn): Promise<GetPublicEntity[]> {
    const response = await axios.post<GetPublicEntity[]>(`${this.baseUrl}/public-entity`, input);
    return response.data;
  }

  async getPublicEntitiesExport(params: ExportPublicEntitiesReceivablesAdminPublicEntityExportPostParams): Promise<AxiosResponse<Blob>> {
    const response = await axios.post(`${this.baseUrl}/public-entity/export`, null, {
      responseType: 'blob',
      params,
    });
    return response;
  }

  async downloadAllDocuments(applicationId: string, companyId: string): Promise<AxiosResponse<Blob>> {
    const response = await axios.get(`${this.baseUrl}/documents/download`, {
      responseType: 'blob',
      params: {
        application_id: applicationId,
        company_id: companyId,
        response_disposition: `attachment; filename="DOCS_${applicationId}.zip"`,
      },
    });
    return response;
  }
}

export default Admin;
