import {
  PaymentDetailsInput,
  GetEmployeeFiltersIn,
  GetReceivablesCompaniesOut,
  GetReceivablesCompanyInfoOut,
  ReceivablesCompanyIn,
  ReceivablesEditEmployeeIn, ReceivablesEmployeeOut, ReceivablesEmployee,
  ApplicationOut,
  CompanyOut,
} from '@repo/common/dtos';
import axios from 'axios';

class Company {
  baseUrl: string;

  constructor(parentUrl: string) {
    this.baseUrl = `${parentUrl}/company`;
  }

  async getCompany(cnpj: string): Promise<GetReceivablesCompanyInfoOut> {
    const response = await axios.get<GetReceivablesCompanyInfoOut>(`${this.baseUrl}/${cnpj}`);
    return response.data;
  }

  async getCompanies(): Promise<GetReceivablesCompaniesOut['companies']> {
    const response = await axios.get<GetReceivablesCompaniesOut>(`${this.baseUrl}`);
    return response.data.companies;
  }

  async createEmployee(cnpj: string, data: GetEmployeeFiltersIn): Promise<ReceivablesEmployee> {
    const response = await axios.post<ReceivablesEmployee>(`${this.baseUrl}/${cnpj}/employee`, data);
    return response.data;
  }

  async updateEmployee(cnpj: string, employeeId: string, data: ReceivablesEditEmployeeIn): Promise<ReceivablesEmployeeOut> {
    const response = await axios.patch<ReceivablesEmployeeOut>(`${this.baseUrl}/${cnpj}/employee/${employeeId}`, data);
    return response.data;
  }

  async updateCompany(companyId: string, data: PaymentDetailsInput): Promise<string> {
    const response = await axios.patch<string>(`${this.baseUrl}/${companyId}`, data);
    return response.data;
  }

  async createCompany(data: ReceivablesCompanyIn): Promise<CompanyOut> {
    const response = await axios.post<CompanyOut>(`${this.baseUrl}`, data);
    return response.data;
  }

  async createApplication(cnpj: string): Promise<ApplicationOut> {
    const response = await axios.post<ApplicationOut>(`${this.baseUrl}/${cnpj}/application`);
    return response.data;
  }
}

export default Company;
