import {
  ReceivablesApplicationHistoryFullOut,
  LimitedApplicationInput,
  LimitedBorderoInput,
  GetReceivablesUserApplicationListOut,
} from '@repo/common/dtos';
import axios from 'axios';

class Application {
  baseUrl: string;

  constructor(parentUrl: string) {
    this.baseUrl = `${parentUrl}/application`;
  }

  async getApplications(companyCnpj?: string): Promise<GetReceivablesUserApplicationListOut> {
    const response = await axios.get<GetReceivablesUserApplicationListOut>(`${this.baseUrl}`, {
      params: {
        company_cnpj: companyCnpj,
      },
    });
    return response.data;
  }

  async getApplication(applicationId: string): Promise<ReceivablesApplicationHistoryFullOut> {
    const response = await axios.get<ReceivablesApplicationHistoryFullOut>(`${this.baseUrl}/${applicationId}`);
    return response.data;
  }

  async updateBorderoTerms(applicationId: string, borderoStatus: boolean): Promise<string> {
    const response = await axios.patch<string>(`${this.baseUrl}/${applicationId}`, undefined, {
      params: {
        bordero_status: borderoStatus ? 'APPROVED' : 'DECLINED',
      },
    });
    return response.data;
  }

  async uploadFile(applicationId: string, documentPathUrl: string, documentName: string, documentType: string | null, description: string): Promise<string> {
    const response = await axios.put<string>(`${this.baseUrl}/${applicationId}/document/upload`, {
      documentPathUrl, documentType, documentName, description,
    });

    return response.data;
  }

  async uploadFiles(applicationId: string, documents: { documentPathUrl: string, documentName: string, documentType: string | null, description: string }[]): Promise<string> {
    const response = await axios.put<string>(`${this.baseUrl}/${applicationId}/document/multi/upload`, {
      documents,
    });

    return response.data;
  }

  async updateLimitedApplication(applicationId: string, data: LimitedApplicationInput): Promise<string> {
    const response = await axios.patch<string>(`${this.baseUrl}/${applicationId}`, data);
    return response.data;
  }

  async updateLimitedBordero(applicationId: string, data: LimitedBorderoInput): Promise<string> {
    const response = await axios.patch<string>(`${this.baseUrl}/${applicationId}/bordero`, data);
    return response.data;
  }
}

export default Application;
